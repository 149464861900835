import React, {useState,useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import Header3 from './header3'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import axios from "axios";
import DataTable from "react-data-table-component";
import Select from 'react-select';
import Spinner from 'react-bootstrap/Spinner';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import BASE_URL from './config';
import Swal from 'sweetalert2';
import dayjs from "dayjs";
import withReactContent from 'sweetalert2-react-content'


function Viewcohorts(){
  const MySwal = withReactContent(Swal)
  const [org_info, setorg] = useState(['']);
  const [coht_info, setcoht] = useState([]);
  const [int_info, setlength] = useState('');
  const deleteURL = BASE_URL+'/deletecohortresultbyID'
  const cohortresultURL = BASE_URL+'/fetchcohortresultbyID'
  const cohortinfoURL = BASE_URL+'/fetchcohortbyID'
  const token = localStorage.getItem("ladpsouthafricaaccesstoken");
  const urlParams = new URLSearchParams(window.location.search);
  const myidd = urlParams.get('myid');
  const history = useHistory();
    
  const options = [
    { value: 'Orientation', label: 'Orientation' },
    { value: 'Emotional Competence', label: 'Emotional Competence' },
    { value: 'Stress & Wellbeing ', label: 'Stress & Wellbeing' },
    { value: 'Summative1', label: 'Summative 1' },
    { value: 'Summative2', label: 'Summative 2' },
    { value: 'Coaching Session', label: 'Coaching Session' },
    { value: 'Driving Service Excellence', label: 'Driving Service Excellence' },
    { value: 'Assertiveness & Managing Tough Interactions', label: 'Assertiveness & Managing Tough Interactions' },
    { value: 'Uncovering Customer Needs', label: 'Uncovering Customer Needs' },
  ];

  const [state , setState] = useState({
    courses: ''
})
      
  const handleChange2 = (fieldName) => (selectedOption) => {
    setState((prevState) => ({
      ...prevState,
      [fieldName]: selectedOption.value
    }));
  };

  const handleGoBack = () => {
    history.goBack();
  };




  const headers2 = { 
    headers: {
      'AccessToken': token,
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': "*",
    }
  };

  const body = {
    'accesstoken': localStorage.getItem("ladpsouthafricastudentaccesstoken"),
    'course_name': state.courses,
    'cohort_id': myidd
  }

  const getcohortdetail = async (e) => {
    axios.post(cohortinfoURL, body, headers2)
    .then((response) => {
      console.log("response received: ", response);
      if(!response){
        setorg(' ');
      }else{
        const sortedArray = response.data.data;
        const numDescending = response.data.data[0];
        // const numDescending = [...sortedArray].sort((a, b) => b.id - a.id);
        setorg(numDescending);
        setorg(numDescending);
      }
    });
  };

  useEffect(() => {
    getcohortdetail();
  }, []);


  const getcohortinfo = async (e) => {
    e.preventDefault();
    if (!myidd||!state.courses) {
      MySwal.fire({
        title: <p>Ensure you select course for filter</p>,
        didOpen: () => {
        },
      }).then(() => {
      });
    }else{
       MySwal.showLoading()
        Swal.close()
        MySwal.fire({
           title: <p>please wait...</p>,
           didOpen: () => {
             MySwal.showLoading()
             axios.post(cohortresultURL, body, headers2)
           .then((response) => {
             console.log("response received: ", response);
             Swal.close()
             const sortedArray = response.data.data;
             console.log(sortedArray)
             setcoht(sortedArray);
             setcoht(sortedArray);
             })
             .catch((err) => {
             Swal.close()
              setcoht(0);
               console.log("AXIOS ERROR: ", err);
               if(!err.response){
               return MySwal.fire(err.message)
               }else{
               return MySwal.fire(err.response.data.message)
               }
             })
           },
         }).then(() => {
         })
   }
  };
  



  const columns = [
    {
      name: 'DATE',
      selector: (row) => dayjs(row.created_at).format("DD MMM YY"),
      sortable: true,
      width: "10rem" ,
        cellExport: (row) => dayjs(row.created_at).format("DD MMM YY")
  },
    {
      name: 'USERNAME ',
      selector: (row) => row.Username,
      sortable: true,
      width: '12rem',
      cellExport: row => row.Username 
    },

    {
      name: 'NAME',
      selector: (row) => row.name,
      sortable: true,
      width: '10rem',
      cellExport: row => row.name 

    },
    {
      name: 'EMAIL',
      selector: (row) => row.email,
      sortable: true,
      width: '18rem',
      cellExport: row => row.email 

    },
    {
      name: 'COURSE ID ',
      selector: (row) => row.course_id,
      sortable: true,
      width: '12rem',
      cellExport: row => row.course_id 
    },
    {
      name: 'COURSE TITLE',
      selector: (row) => row.course_title,
      sortable: true,
      width: '18rem',
      cellExport: row => row.course_title 
    },
    {
      name: 'PERCENTAGE',
      selector: (row) => `${(Number(row.percentage) * 100).toFixed(0)}%`,
      sortable: true,
      width: '12rem',
      cellExport: row => `${Number(row.percentage) * 100}%` 
    },
    {
      name: 'STEPS COMPLETED',
      selector: (row) => row.steps_completed,
      sortable: true,
      width: '12rem',
      cellExport: row => row.steps_completed 
    },
    {
      name: 'STEPS TOTAL',
      selector: (row) => row.steps_total,
      sortable: true,
      width: '12rem',
      cellExport: row => row.steps_total 
    },
    {
      name: 'COURSE COMPLETED',
      selector: (row) => row.course_completed,
      sortable: true,
      width: '15rem',
      cellExport: row => row.course_completed 
    },
    {
      name: 'COURSE COMPLETED ON',
      selector: (row) => row.course_completed_on,
      sortable: true,
      width: '15rem',
      cellExport: row => row.course_completed_on 
    },
    {
      name: 'FIRST NAME',
      selector: (row) => row.First_Name,
      sortable: true,
      width: '12rem',
      cellExport: row => row.First_Name 
    },
    {
      name: 'LAST NAME',
      selector: (row) => row.Last_Name,
      sortable: true,
      width: '12rem',
      cellExport: row => row.Last_Name 
    },
    {
      name: 'NICK NAME',
      selector: (row) => row.Nick_Name,
      sortable: true,
      width: '12rem',
      cellExport: row => row.Nick_Name 
    },
    {
      name: 'GROUP',
      selector: (row) => row.result_group,
      sortable: true,
      width: '20rem',
      cellExport: row => row.result_group 
    },
    {
      name: 'BUSINESS UNIT',
      selector: (row) => row.course_title,
      sortable: true,
      width: '20rem',
      cellExport: row => row.course_title 
    },
    {
      name: 'EE NUMBER',
      selector: (row) => row.ee_number,
      sortable: true,
      width: '12rem',
      cellExport: row => row.ee_number 
    },
    {
      name: 'RACE',
      selector: (row) => row.race,
      sortable: true,
      width: '12rem',
      cellExport: row => row.race 
    },
    {
      name: 'GENDER',
      selector: (row) => row.gender,
      sortable: true,
      width: '12rem',
      cellExport: row => row.gender 
    },
    {
      name: 'TITLE ROLE',
      selector: (row) => row.title_role,
      sortable: true,
      width: '18rem',
      cellExport: row => row.title_role 
    },
    {
      name: 'PHONE',
      selector: (row) => row.phone,
      sortable: true,
      width: '12rem',
      cellExport: row => row.phone 
    },
    {
      name: 'SHARED EMAIL ADDRESS',
      selector: (row) => row.shared_email_address,
      sortable: true,
      width: '12rem',
      cellExport: row => row.shared_email_address 
    },
    {
      name: 'COURSE STARTED ON',
      selector: (row) => row.course_started_on,
      sortable: true,
      width: '15rem',
      cellExport: row => row.course_started_on 
    },
    {
      name: 'COURSE TOTAL TIME ON',
      selector: (row) => row.course_total_time_on,
      sortable: true,
      width: '15rem',
      cellExport: row => row.course_total_time_on 
    },
    {
      name: 'COURSE LAST STEP ID',
      selector: (row) => row.course_last_step_id,
      sortable: true,
      width: '15rem',
      cellExport: row => row.course_last_step_id 
    },
    {
      name: 'COURSE LAST STEP ID',
      selector: (row) => row.course_last_step_type,
      sortable: true,
      width: '15rem',
      cellExport: row => row.course_last_step_type 
    },
    {
      name: 'COURSE LAST STEP TITLE',
      selector: (row) => row.course_last_step_title,
      sortable: true,
      width: '15rem',
      cellExport: row => row.course_last_step_title 
    },
    {
      name: 'LAST LOGIN DATE',
      selector: (row) => row.course_last_login_date,
      sortable: true,
      width: '15rem',
      cellExport: row => row.course_last_login_date 
    }
];

const data=coht_info;
const tableData = {
  columns,
  data
};

  

  const deleteresult = async (e) => {
    e.preventDefault();
    if (!myidd) {
      MySwal.fire({
        title: <p>Unable to get Cohort ID</p>,
        didOpen: () => {
        },
      }).then(() => {
      });
    }else{
       MySwal.showLoading()
        Swal.close()
        MySwal.fire({
           title: <p>please wait...</p>,
           didOpen: () => {
             MySwal.showLoading()
             axios.post(deleteURL, body, headers2)
           .then((response) => {
             console.log("response received: ", response);
             Swal.close()
             Swal.fire({
               title: "Successful",
               text: "Result Deleted successfully",
               icon: "success",
               showCancelButton: false,
               confirmButtonColor: '#3085d6',
               confirmButtonText: "Okay",
               closeOnCancel: true,
             }).then(result => {
           window.location.reload(false);
             })
             })
             .catch((err) => {
             Swal.close()
               console.log("AXIOS ERROR: ", err);
               if(!err.response){
               return MySwal.fire(err.message)
               }else{
               return MySwal.fire(err.response.data.message)
               }
             })
           },
         }).then(() => {
         })
   }
  };


  const uploadresults = () => {
    const selectedID = myidd;
    if (selectedID) {
      const nextPageUrl = '/uploadresults';
      window.location.href = nextPageUrl;
      window.location.href = "uploadresults?myid=" + selectedID;
    } else {
      console.error(`Data for ID ${myidd} not found`);
    }
  }

  const report = () => {
    const selectedID = myidd;
    if (selectedID) {
      const nextPageUrl = '/reports';
      window.location.href = nextPageUrl;
      window.location.href = "reports?myid=" + selectedID;
    } else {
      console.error(`Data for ID ${myidd} not found`);
    }
  }


    

      return (
     <div>
     <Header3/>
     <div>
        <div className="toolbar py-3 py-lg-5" >
          <div className=" container-xxl  d-flex flex-stack flex-wrap">
            <div className="page-title d-flex flex-column">
            <span className="page-desc text-muted fs-7 fw-semibold pt-1">
                <a onClick={uploadresults} className="btn btn-sm btn-primary">Go Back</a>
                </span>
                <br/>
              <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
               Cohort Result 
              </h1>
            </div>
          </div>
        </div>
        <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start  container-xxl ">
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card mb-5 mb-xl-8">
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">Cohort Results for {org_info.cohorts_title} </span>
                </h3>
                <div className="card-toolbar">
                <a onClick={uploadresults} className="btn btn-sm btn-info">Upload Results</a>
                 </div>
                
              </div>
              <div className='row'>
                             <div className='col-lg-4' style={{padding:'30px'}}>
                                  <label className=" fw-semibold fs-6 mb-2">Filter by Courses uploaded</label>
                                  <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.courses, label: state.courses }}  onChange={handleChange2('courses')}  
                                            options={options}
                                          />
                                </div>
                                <div className='col-lg-7'>
                                  <br/>
                                  <br/>
                                  <br/>
                                <a onClick={getcohortinfo} className="btn btn-sm btn-info">Filter Selected Result</a>&nbsp;&nbsp;&nbsp;
                                <a onClick={deleteresult} className="btn btn-sm btn-info">Delete Selected Result</a> &nbsp;&nbsp;&nbsp;
                                <a onClick={report} className="btn btn-sm btn-primary">Generate Report</a>
                                </div>
                                
                             </div>
              <div className="card-body py-3">
                {/*begin::Table container*/}
                <div className="table-responsive">
                  {/*begin::Table*/}
                  <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                  {coht_info == null ? <Spinner animation="border" /> : ""}
                  <DataTableExtensions {...tableData}>
                                <DataTable className=""
                                  columns={columns}
                                  data={data}
                                  noHeader
                                  defaultSortField="id"
                                  defaultSortAsc={false}
                                  pagination
                                  exportHeaders= {true}
                                  highlightOnHover
                                />
                              </DataTableExtensions>
                  </table>
                  {/*end::Table*/}
                </div>
                {/*end::Table container*/}
              </div>
              {/*begin::Body*/}
            </div>
          </div>
          </div>
      </div>
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Viewcohorts;
