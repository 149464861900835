import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import Header3 from './header3'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import axios from "axios";
import DataTable from "react-data-table-component";
import Spinner from 'react-bootstrap/Spinner';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import BASE_URL from './config';
import { useHistory } from 'react-router-dom';
import dayjs from "dayjs";
import withReactContent from 'sweetalert2-react-content'



function Viewstudent(){
  const MySwal = withReactContent(Swal)
  const [org_info, setorg] = useState(['']);
  const [summ_info, setsum] = useState([]);
  const [int_info, setlength] = useState('');
  const mysum = localStorage.getItem('ladpsouthafricasuppliersummaryarray');
  const orgURL = BASE_URL+'/fetchstudentbyUsername'
  const enableURL = BASE_URL+'/activateUser'
  const token = localStorage.getItem("ladpsouthafricastudentaccesstoken");
  const urlParams = new URLSearchParams(window.location.search);
  const myidd = urlParams.get('myid');
  const myusername = urlParams.get('username');
  console.log(mysum)
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  const editorganization = (selectedId) => {
    const selectedID = selectedId;
    if (selectedID) {
      window.location.href = "editstudent?myid=" + selectedID;
    } else {
      console.error(`Data for ID ${selectedId} not found`);
    }
  }

  const headers2 = { 
    headers: {
      'AccessToken': token,
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': "*",
    }
  };

  const body = {
    'accesstoken': localStorage.getItem("ladpsouthafricastudentaccesstoken"),
    'cohort_id': myidd,
    'Username': myusername
  }

  const getsuppinfo = async (e) => {
    axios.post(orgURL, body, headers2)
    .then((response) => {
      console.log("response received: ", response);
      if(!response){
        setorg(0);
        setsum(0);
      }else{
        const sortedArray = response.data.data[0];
        const sortedArrays = response.data.data;
        setsum(sortedArrays);
        setsum(sortedArrays);
        console.log(sortedArray)
        setorg(sortedArray);
        setorg(sortedArray);
      }
    });
  };
  


  const enable = async (e) => {
    e.preventDefault();
    if (!myidd) {
      MySwal.fire({
        title: <p>Unable to get Client ID</p>,
        didOpen: () => {
        },
      }).then(() => {
      });
    }else{
       MySwal.showLoading()
        Swal.close()
        MySwal.fire({
           title: <p>please wait...</p>,
           didOpen: () => {
             MySwal.showLoading()
             axios.post(enableURL, body, headers2)
           .then((response) => {
             console.log("response received: ", response);
             Swal.close()
             Swal.fire({
               title: "Successful",
               text: "Client Updated successfully",
               icon: "success",
               showCancelButton: false,
               confirmButtonColor: '#3085d6',
               confirmButtonText: "Okay",
               closeOnCancel: true,
             }).then(result => {
           window.location.reload(false);
             })
             })
             .catch((err) => {
             Swal.close()
               console.log("AXIOS ERROR: ", err);
               if(!err.response){
               return MySwal.fire(err.message)
               }else{
               return MySwal.fire(err.response.data.message)
               }
             })
           },
         }).then(() => {
         })
   }
  };



  useEffect(() => {
    getsuppinfo();
  }, []);


  const columns = [
    {
      name: 'CREATED DATE',
      selector: (row) => dayjs(row.created_at).format("DD MMM YY"),
      sortable: true,
      width: "10rem" ,
        cellExport: (row) => dayjs(row.created_at).format("DD MMM YY")
  },
  {
    name: 'COURSE NAME',
    selector: (row) => row.course_name,
    sortable: true,
    width: '12rem',
    cellExport: row => row.course_name
  },
    {
      name: 'COURSE ID ',
      selector: (row) => row.course_id,
      sortable: true,
      width: '12rem',
      cellExport: row => row.course_id 
    },
    {
      name: 'COURSE TITLE',
      selector: (row) => row.course_title,
      sortable: true,
      width: '18rem',
      cellExport: row => row.course_title 
    },
    {
      name: 'PERCENTAGE',
      selector: (row) => `${Number(row.percentage) * 100}%`,
      sortable: true,
      width: '12rem',
      cellExport: row => `${Number(row.percentage) * 100}%` 
    },
    {
      name: 'STEPS COMPLETED',
      selector: (row) => row.steps_completed,
      sortable: true,
      width: '12rem',
      cellExport: row => row.steps_completed 
    },
    {
      name: 'STEPS TOTAL',
      selector: (row) => row.steps_total,
      sortable: true,
      width: '12rem',
      cellExport: row => row.steps_total 
    },
    {
      name: 'COURSE COMPLETED',
      selector: (row) => row.course_completed,
      sortable: true,
      width: '15rem',
      cellExport: row => row.course_completed 
    },
    {
      name: 'COURSE COMPLETED ON',
      selector: (row) => row.course_completed_on,
      sortable: true,
      width: '15rem',
      cellExport: row => row.course_completed_on 
    },
    {
      name: 'GROUP',
      selector: (row) => row.result_group,
      sortable: true,
      width: '20rem',
      cellExport: row => row.result_group 
    },
    {
      name: 'COURSE STARTED ON',
      selector: (row) => row.course_started_on,
      sortable: true,
      width: '15rem',
      cellExport: row => row.course_started_on 
    },
    {
      name: 'COURSE TOTAL TIME ON',
      selector: (row) => row.course_total_time_on,
      sortable: true,
      width: '15rem',
      cellExport: row => row.course_total_time_on 
    },
    {
      name: 'COURSE LAST STEP ID',
      selector: (row) => row.course_last_step_id,
      sortable: true,
      width: '15rem',
      cellExport: row => row.course_last_step_id 
    },
    {
      name: 'COURSE LAST STEP ID',
      selector: (row) => row.course_last_step_type,
      sortable: true,
      width: '15rem',
      cellExport: row => row.course_last_step_type 
    },
    {
      name: 'COURSE LAST STEP TITLE',
      selector: (row) => row.course_last_step_title,
      sortable: true,
      width: '15rem',
      cellExport: row => row.course_last_step_title 
    },
    {
      name: 'LAST LOGIN DATE',
      selector: (row) => row.course_last_login_date,
      sortable: true,
      width: '15rem',
      cellExport: row => row.course_last_login_date 
    }
];

const data=summ_info;
const tableData = {
  columns,
  data
};
    

      return (
     <div>
     <Header3/>
     <div>
        <div className="toolbar py-3 py-lg-5" >
          <div className=" container-xxl  d-flex flex-stack flex-wrap">
            <div className="page-title d-flex flex-column">
              <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                View Student Information
                <span className="page-desc text-muted fs-7 fw-semibold pt-1">
                </span>
              </h1>
            </div>
          </div>
        </div>
        <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start  container-xxl ">
          <div className="content flex-row-fluid" id="kt_content">
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
              <div className="content flex-row-fluid" id="kt_content">
                <div className="card mb-5 mb-xl-8">    
                </div>
                <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start  container-xxl ">
                  <div className="content flex-row-fluid" id="kt_content">
                    <a href="/studentlist" className="btn btn-sm btn-danger">Go back</a>
                    <br />
                    <br />
                    <br />
                    <div className="d-flex flex-column flex-xl-row">
                      <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
                        <div className="card mb-5 mb-xl-8">
                          <div className="card-body pt-15">
                            <div className="d-flex flex-center flex-column mb-5">
                              <br/>
                              <a href="#" className="fs-3 text-gray-800 text-hover-primary fw-bold mb-1">
                              {org_info.First_Name}	{org_info.Last_Name} 
                              </a>
                              <div className="fs-5 fw-semibold text-muted mb-6">
                                 {org_info.business_unit}
                              </div>
                              <div className="fs-5 fw-semibold text-muted mb-6">
                              <small>{org_info.title_role}</small>
                              </div>
                              <div className="d-flex flex-wrap flex-center">
                                <div className="border border-gray-300 border-dashed rounded py-3 px-3 mb-3">
                                  <div className="fs-4 fw-bold text-gray-700">
                                    <span className="w-75px">{summ_info.length}</span>
                                  </div>
                                  <div className="fw-semibold text-muted"> Courses Uploaded</div>
                                </div>
                                {/* <div className="border border-gray-300 border-dashed rounded py-3 px-3 mx-4 mb-3">
                                  <div className="fs-4 fw-bold text-gray-700">
                                    <span className="w-50px">88% </span>
                                  </div>
                                  <div className="fw-semibold text-muted">Overall Score</div>
                                </div> */}
                               
                                
                              </div>
                            </div>
                            <div className="d-flex flex-stack fs-4 py-3">
                              <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#kt_customer_view_details" role="button" aria-expanded="false" aria-controls="kt_customer_view_details">
                                Details
                               
                              </div>
                            </div>
                            <div className="separator separator-dashed my-3" />
                            <div id="kt_customer_view_details" className="collapse show">
                              <div className="py-5 fs-6">
                                <div className="badge badge-light-info d-inline">Registered Student</div>
                                <div className="fw-bold mt-5">Student ID </div>
                                <div className="text-gray-600">{org_info.user_id}</div>
                                <div className="fw-bold mt-5">Username </div>
                                <div className="text-gray-600">{org_info.Username}</div>
                                <div className="fw-bold mt-5">Student Email</div>
                                <div className="text-gray-600"><a href="#" className="text-gray-600 text-hover-primary">{org_info.email}</a></div>
                                <div className="fw-bold mt-5">Student Username</div>
                                <div className="text-gray-600">{org_info.username}</div>
                                <div className="fw-bold mt-5">Gender</div>
                                <div className="text-gray-600">{org_info.gender}</div>
                                <div className="fw-bold mt-5">EE Number</div>
                                <div className="text-gray-600">{org_info.ee_number}</div>
                                <div className="fw-bold mt-5">Race</div>
                                <div className="text-gray-600">{org_info.race}</div>
                                <div className="fw-bold mt-5">Gender</div>
                                <div className="text-gray-600">{org_info.gender}</div>
                                <div className="fw-bold mt-5">Phone</div>
                                <div className="text-gray-600">{org_info.phone}</div>
                                
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex-lg-row-fluid ms-lg-15">
                        <div className="tab-content" id="myTabContent">
                          <div className="tab-pane fade show active" id="kt_customer_view_overview_tab" role="tabpanel">
                            <div className="card pt-4 mb-6 mb-xl-9">
                              <div className="card-header border-0">
                                <div className="card-title">
                                  <h2>Completed Courses</h2>
                                </div>
                              </div>
                              <div className="card-body py-3">
                {/*begin::Table container*/}
                <div className="table-responsive">
                  {/*begin::Table*/}
                  <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                  {summ_info == null ? <Spinner animation="border" /> : ""}
                  <DataTableExtensions {...tableData}>
                                <DataTable className=""
                                  columns={columns}
                                  data={data}
                                  noHeader
                                  defaultSortField="id"
                                  defaultSortAsc={false}
                                  pagination
                                  exportHeaders= {true}
                                  highlightOnHover
                                />
                              </DataTableExtensions>
                  </table>
                  {/*end::Table*/}
                </div>
                {/*end::Table container*/}
              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Viewstudent;
