import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import Header3 from './header3'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import Countries from './countries';
import withReactContent from 'sweetalert2-react-content'
import BusinessCategories from './BusinessCategories';
import BBEEE from './bbeee';
import Select from 'react-select';
import BASE_URL from './config';
import axios from "axios";



function Viewuser(){
  const MySwal = withReactContent(Swal)
  const [org_info, setorg] = useState([]);
  const orgURL = BASE_URL+'/fetchUserById'
  const orgupdateURL = BASE_URL+'/updateUser'

  const urlParams = new URLSearchParams(window.location.search);
  const myidd = urlParams.get('myid');
  const [state , setState] = useState({
    lastname: '',
    firstname: '',
    contactphone: '',
    contactaddress: '',
    contactemail: '',
    client_id: '',
    role: '',
    password: '',
    is_active: ''
})

  const body = {
    'accesstoken': localStorage.getItem("ladpsouthafricastudentaccesstoken"),
    'userId': myidd
  }

  const getorginfo = async (e) => {
    axios.post(orgURL, body, headers2)
    .then((response) => {
      console.log("response received: ", response);
      if(!response){
        setorg(0);
      }else{
        const sortedArray = response.data.data[0];
        console.log(sortedArray)
        setorg(sortedArray);
        setorg(sortedArray);
      }
    });
  };


  useEffect(() => {
    getorginfo();
      setState((prevState) => ({
        ...prevState,
        firstname: org_info.firstname,
        lastname: org_info.lastname,
        contactphone: org_info.phone,
        contactaddress: org_info.address,
        contactemail: org_info.email,
        role: org_info.user_role,
        is_active: org_info.is_active,
        client_id: org_info.client_id
      }));
    }, [org_info.firstname]);



const handleChange = (e) => {
  const {id , value} = e.target   
  setState(prevState => ({
      ...prevState,
      [id] : value
  }))
} 

const token = localStorage.getItem('ladpsouthafricastudentaccesstoken');
const headers2 = { 
  headers: {
    'AccessToken': token,
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': "*",
  }
};

const editUser = async (e) => {
  e.preventDefault();
  if (!state.firstname ||!state.lastname || !state.contactphone|| !state.contactaddress|| !state.contactemail|| !state.role|| !state.password) {
    MySwal.fire({
      title: <p>Please enter all required fields</p>,
      didOpen: () => {
      },
    }).then(() => {
    });
  }else{
     MySwal.showLoading()
    
   
const postData = {
    'accesstoken': localStorage.getItem("ladpsouthafricaaccesstoken"),
     'firstname': state.firstname,
     'lastname': state.lastname,
     'phone': state.contactphone,
     'email': state.contactemail,
     'user_role': state.role,
     'address': state.contactaddress,
     'password': state.password,
     'is_active': state.is_active,
     'userId': myidd,
     'client_id': state.client_id
     }
     console.log(postData);
      Swal.close()
     MySwal.fire({
         title: <p>please wait...</p>,
         didOpen: () => {
           MySwal.showLoading()
           axios.post(orgupdateURL, postData, headers2)
         .then((response) => {
           console.log("response received: ", response);
           Swal.close()
           Swal.fire({
             title: "Successful",
             text: "User Updated successfully",
             icon: "success",
             showCancelButton: false,
             confirmButtonText: "Okay",
             confirmButtonColor: '#3085d6',
             closeOnCancel: true,
           }).then(result => {
         window.location.reload(false);
           })
           })
           .catch((err) => {
           Swal.close()
             console.log("AXIOS ERROR: ", err);
             if(!err.response){
             return MySwal.fire(err.message)
             }else{
             return MySwal.fire(err.response.data.message)
             }
           })
         },
       }).then(() => {
       })
 }
};




      return (
     <div>
     <Header3/>
     <br/>
     <br/>
     <br/>
     <br/>
    
     <div className="d-flex flex-column flex-xl-row">
        <div className="flex-lg-row-fluid ms-lg-15">
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="kt_customer_view_overview_tab" role="tabpanel">
            <div className="card mb-5 mb-xl-10">
        <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
          <div className="card-title m-0">
            <h3 className="fw-bold m-0">Manage Users</h3>
          </div>
        </div>
        <div id="kt_account_settings_profile_details" className="collapse show">
        <form id="kt_account_profile_details_form" className="form">
            <div className="card-body border-top p-9">
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Firstname</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.firstname} id="firstname" name="firstname" onChange={handleChange} />
                </div>  
                <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Lastname</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.lastname} id="lastname" name="lastname" onChange={handleChange} />
                </div>              
                <div className='col-lg-4'>
                  <label className=" fw-semibold fs-6 mb-2">Phone </label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.contactphone} id="contactphone" name="contactphone" onChange={handleChange} />
                </div>
                
              </div>
             
              
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Email</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.contactemail} id="contactemail" name="contactemail" onChange={handleChange} />
                </div>
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2"> Address</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.contactaddress} id="contactaddress" name="contactaddress" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2"> Update Password</label>
                  <input type="password" className="form-control  mb-3 mb-lg-0"  value={state.password} id="password" name="password" onChange={handleChange} />
                </div>
               
              </div>

              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2"> Select Role</label>
                  <select aria-label="Select a Language" value={state.role} id="role" name="role" data-placeholder="Select a category..." onChange={handleChange} className="form-select form-select-solid form-select-lg">
                    <option value>Select a Role...</option>
                    <option  value="sub_admin">Sub Admin</option>
                    {/* <option value="supplier_admin">Supplier Admin</option> */}
                          {/* <option  value="client_admin">Client Admin</option> */}

                  </select>
                 </div>
                <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2"> Update Status</label>
                  <select aria-label="Select a Language" value={state.is_active} id="is_active" name="is_active" data-placeholder="Select a category..." onChange={handleChange} className="form-select form-select-solid form-select-lg">
                    <option value>Update Status</option>
                    <option  value="1">Active</option>
                    <option  value="0">Inactive</option>
                    {/* <option value="supplier_admin">Supplier Admin</option> */}
                  </select>
                 </div>
              </div>
            </div>
            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <a href='user'  className="btn btn-light btn-active-light-danger me-2">Discard</a>
              <button type="submit" className="btn btn-info" onClick={editUser}>Update User</button>
            </div>
          </form>
        </div>
      </div>
            </div>
          </div>
        </div>
      </div>
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Viewuser;
