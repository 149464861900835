import React, {useState,useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import Header3 from './header3'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import axios from "axios";
import DataTable from "react-data-table-component";
import Select from 'react-select';
import Spinner from 'react-bootstrap/Spinner';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import BASE_URL from './config';
import Swal from 'sweetalert2';
import dayjs from "dayjs";
import withReactContent from 'sweetalert2-react-content'


function Reports(){
  const MySwal = withReactContent(Swal)
  const [org_info, setorg] = useState(['']);
  const [coht_info, setcoht] = useState([]);
  const [int_info, setlength] = useState('');
  const deleteURL = BASE_URL+'/deletecohortresultbyID'
  const cohortresultURL = BASE_URL+'/fetchallcohortresultbyID'
  const cohortinfoURL = BASE_URL+'/fetchcohortbyID'
  const token = localStorage.getItem("ladpsouthafricaaccesstoken");
  const urlParams = new URLSearchParams(window.location.search);
  const myidd = urlParams.get('myid');
  const history = useHistory();
    
  const options = [
    { value: 'Orientation', label: 'Orientation' },
    { value: 'Emotional Competence', label: 'Emotional Competence' },
    { value: 'Stress & Wellbeing ', label: 'Stress & Wellbeing' },
    { value: 'Summative1', label: 'Summative 1' },
    { value: 'Summative2', label: 'Summative 2' },
    { value: 'Coaching Session', label: 'Coaching Session' },
    { value: 'Driving Service Excellence', label: 'Driving Service Excellence' },
    { value: 'Assertiveness & Managing Tough Interactions', label: 'Assertiveness & Managing Tough Interactions' },
    { value: 'Uncovering Customer Needs', label: 'Uncovering Customer Needs' },
  ];

  const [state , setState] = useState({
    courses: ''
})
      
  const handleChange2 = (fieldName) => (selectedOption) => {
    setState((prevState) => ({
      ...prevState,
      [fieldName]: selectedOption.value
    }));
  };

  const handleGoBack = () => {
    history.goBack();
  };




  const headers2 = { 
    headers: {
      'AccessToken': token,
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': "*",
    }
  };

  const body = {
    'accesstoken': localStorage.getItem("ladpsouthafricastudentaccesstoken"),
    'course_name': state.courses,
    'cohort_id': myidd
  }

  const getcohortdetail = async (e) => {
    axios.post(cohortinfoURL, body, headers2)
    .then((response) => {
      console.log("response received: ", response);
      if(!response){
        setorg(' ');
      }else{
        const sortedArray = response.data.data;
        const numDescending = response.data.data[0];
        // const numDescending = [...sortedArray].sort((a, b) => b.id - a.id);
        setorg(numDescending);
        setorg(numDescending);
      }
    });
  };

  const getcohortinfo = async (e) => {
    axios.post(cohortresultURL, body, headers2)
    .then((response) => {
      console.log("response received: ", response);
      if(!response){
        setcoht(' ');
      }else{
        const sortedArray = response.data.data;
        const numDescending = response.data.data;
        // const numDescending = [...sortedArray].sort((a, b) => b.id - a.id);
        setcoht(numDescending);
        setcoht(numDescending);
      }
    });
  };


  useEffect(() => {
    getcohortdetail();
    getcohortinfo();
  }, []);
  



  const columns = [
    {
      name: 'EE NUMBER',
      selector: (row) => row.ee_number,
      sortable: true,
      width: '12rem',
      cellExport: row => row.ee_number 
    },
    {
      name: 'COURSE NAME ',
      selector: (row) => row.course_name,
      sortable: true,
      width: '12rem',
      cellExport: row => row.course_name 
    },
    {
      name: 'COACH ',
      selector: (row) => row.Username,
      sortable: true,
      width: '12rem',
      cellExport: row => row.Username 
    },
   
    {
      name: 'FIRST NAME ',
      selector: (row) => row.Username,
      sortable: true,
      width: '12rem',
      cellExport: row => row.Username 
    },
    {
      name: 'SURNAME',
      selector: (row) => row.name,
      sortable: true,
      width: '10rem',
      cellExport: row => row.name 
    },
    {
      name: 'ID NUMBER',
      selector: (row) => row.Username,
      sortable: true,
      width: '10rem',
      cellExport: row => row.Username 
    },
    {
      name: 'EMAIL',
      selector: (row) => row.email,
      sortable: true,
      width: '18rem',
      cellExport: row => row.email 
    },
    {
      name: 'Orientation',
      selector: (row) => {
        // Assuming row.course_data is the JSON array
        const course = coht_info.find(course => course.course_name === 'Orientation' && course.Username === row.Username);
        if (course) {
          return `${(Number(row.percentage) * 100).toFixed(0)}%`; // Format the percentage as needed
        }
        return 'N/A'; // Return 'N/A' or any default value if 'orientation' course is not found
      },
      sortable: true,
      width: '12rem',
      cellExport: row => {
        const course = coht_info.find(course => course.course_name === 'Orientation' && course.Username === row.Username);
        if (course) {
          return `${(Number(row.percentage) * 100).toFixed(0)}%`;
        }
        return 'N/A';
      }
    },
    {
      name: 'Emotional Competence',
      selector: (row) => {
        // Assuming row.course_data is the JSON array
        const course = coht_info.find(course => course.course_name === 'Emotional Competence' && course.Username === row.Username);
        if (course) {
          return `${(Number(row.percentage) * 100).toFixed(0)}%`; // Format the percentage as needed
        }
        return 'N/A'; // Return 'N/A' or any default value if 'orientation' course is not found
      },
      sortable: true,
      width: '12rem',
      cellExport: row => {
        const course = coht_info.find(course => course.course_name === 'Emotional Competence' && course.Username === row.Username);
        if (course) {
          return `${(Number(row.percentage) * 100).toFixed(0)}%`;
        }
        return 'N/A';
      }
    },
    {
      name: 'Stress & Wellbeing',
      selector: (row) => {
        // Assuming row.course_data is the JSON array
        const course = coht_info.find(course => course.course_name === 'Stress & Wellbeing' && course.Username === row.Username);
        if (course) {
          return `${(Number(row.percentage) * 100).toFixed(0)}%`; // Format the percentage as needed
        }
        return 'N/A'; // Return 'N/A' or any default value if 'orientation' course is not found
      },
      sortable: true,
      width: '15rem',
      cellExport: row => {
        const course = coht_info.find(course => course.course_name === 'Stress & Wellbeing' && course.Username === row.Username);
        if (course) {
          return `${(Number(row.percentage) * 100).toFixed(0)}%`;
        }
        return 'N/A';
      }
    },
    {
      name: 'Summative1',
      selector: (row) => {
        // Assuming row.course_data is the JSON array
        const course = coht_info.find(course => course.course_name === 'Summative1' && course.Username === row.Username);
        if (course) {
          return `${(Number(row.percentage) * 100).toFixed(0)}%`; // Format the percentage as needed
        }
        return 'N/A'; // Return 'N/A' or any default value if 'orientation' course is not found
      },
      sortable: true,
      width: '15rem',
      cellExport: row => {
        const course = coht_info.find(course => course.course_name === 'Summative1' && course.Username === row.Username);
        if (course) {
          return `${(Number(row.percentage) * 100).toFixed(0)}%`;
        }
        return 'N/A';
      }
    },
    {
      name: 'Summative2',
      selector: (row) => {
        // Assuming row.course_data is the JSON array
        const course = coht_info.find(course => course.course_name === 'Summative2' && course.Username === row.Username);
        if (course) {
          return `${(Number(row.percentage) * 100).toFixed(0)}%`; // Format the percentage as needed
        }
        return 'N/A'; // Return 'N/A' or any default value if 'orientation' course is not found
      },
      sortable: true,
      width: '15rem',
      cellExport: row => {
        const course = coht_info.find(course => course.course_name === 'Summative2' && course.Username === row.Username);
        if (course) {
          return `${(Number(row.percentage) * 100).toFixed(0)}%`;
        }
        return 'N/A';
      }
    },
    {
      name: 'Coaching Session',
      selector: (row) => {
        // Assuming row.course_data is the JSON array
        const course = coht_info.find(course => course.course_name === 'Coaching Session' && course.Username === row.Username);
        if (course) {
          return `${(Number(row.percentage) * 100).toFixed(0)}%`; // Format the percentage as needed
        }
        return 'N/A'; // Return 'N/A' or any default value if 'orientation' course is not found
      },
      sortable: true,
      width: '15rem',
      cellExport: row => {
        const course = coht_info.find(course => course.course_name === 'Coaching Session' && course.Username === row.Username);
        if (course) {
          return `${(Number(row.percentage) * 100).toFixed(0)}%`;
        }
        return 'N/A';
      }
    },
    {
      name: 'Driving Service Excellence',
      selector: (row) => {
        // Assuming row.course_data is the JSON array
        const course = coht_info.find(course => course.course_name === 'Driving Service Excellence' && course.Username === row.Username);
        if (course) {
          return `${(Number(row.percentage) * 100).toFixed(0)}%`; // Format the percentage as needed
        }
        return 'N/A'; // Return 'N/A' or any default value if 'orientation' course is not found
      },
      sortable: true,
      width: '19rem',
      cellExport: row => {
        const course = coht_info.find(course => course.course_name === 'Driving Service Excellence' && course.Username === row.Username);
        if (course) {
          return `${(Number(row.percentage) * 100).toFixed(0)}%`;
        }
        return 'N/A';
      }
    },
    {
      name: 'Assertiveness & Managing Tough Interactions',
      selector: (row) => {
        // Assuming row.course_data is the JSON array
        const course = coht_info.find(course => course.course_name === 'Assertiveness & Managing Tough Interactions' && course.Username === row.Username);
        if (course) {
          return `${(Number(row.percentage) * 100).toFixed(0)}%`; // Format the percentage as needed
        }
        return 'N/A'; // Return 'N/A' or any default value if 'orientation' course is not found
      },
      sortable: true,
      width: '25rem',
      cellExport: row => {
        const course = coht_info.find(course => course.course_name === 'Assertiveness & Managing Tough Interactions' && course.Username === row.Username);
        if (course) {
          return `${(Number(row.percentage) * 100).toFixed(0)}%`;
        }
        return 'N/A';
      }
    },
    {
      name: 'Customer Needs',
      selector: (row) => {
        // Assuming row.course_data is the JSON array
        const course = coht_info.find(course => course.course_name === 'Customer Needs' && course.Username === row.Username);
        if (course) {
          return `${(Number(row.percentage) * 100).toFixed(0)}%`; // Format the percentage as needed
        }
        return 'N/A'; // Return 'N/A' or any default value if 'orientation' course is not found
      },
      sortable: true,
      width: '22rem',
      cellExport: row => {
        const course = coht_info.find(course => course.course_name === 'Customer Needs' && course.Username === row.Username);
        if (course) {
          return `${(Number(row.percentage) * 100).toFixed(0)}%`;
        }
        return 'N/A';
      }
    },
   
];

const data=coht_info;
const tableData = {
  columns,
  data
};

  




  const uploadresults = () => {
    const selectedID = myidd;
    if (selectedID) {
      const nextPageUrl = '/uploadresults';
      window.location.href = nextPageUrl;
      window.location.href = "uploadresults?myid=" + selectedID;
    } else {
      console.error(`Data for ID ${myidd} not found`);
    }
  }

  const report = () => {
    const selectedID = myidd;
    if (selectedID) {
      const nextPageUrl = '/reports';
      window.location.href = nextPageUrl;
      window.location.href = "reports?myid=" + selectedID;
    } else {
      console.error(`Data for ID ${myidd} not found`);
    }
  }

      return (
     <div>
     <Header3/>
     <div>
        <div className="toolbar py-3 py-lg-5" >
          <div className=" container-xxl  d-flex flex-stack flex-wrap">
            <div className="page-title d-flex flex-column">
            <span className="page-desc text-muted fs-7 fw-semibold pt-1">
                <a onClick={handleGoBack} className="btn btn-sm btn-primary">Go Back</a>
                </span>
                <br/>
              <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
               Cohort Report
              </h1>
            </div>
          </div>
        </div>
        <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start  container-xxl ">
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card mb-5 mb-xl-8">
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">Cohort Report for {org_info.cohorts_title} </span>
                </h3>
                <div className="card-toolbar">
              
                 </div>
                
              </div>
              <div className="card-body py-3">
                {/*begin::Table container*/}
                <div className="table-responsive">
                  {/*begin::Table*/}
                  <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                  {coht_info == null ? <Spinner animation="border" /> : ""}
                  <DataTableExtensions {...tableData}>
                                <DataTable className=""
                                  columns={columns}
                                  data={data}
                                  noHeader
                                  defaultSortField="id"
                                  defaultSortAsc={false}
                                  pagination
                                  exportHeaders= {true}
                                  highlightOnHover
                                />
                              </DataTableExtensions>
                  </table>
                  {/*end::Table*/}
                </div>
                {/*end::Table container*/}
              </div>
              {/*begin::Body*/}
            </div>
          </div>
          </div>
      </div>
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Reports;
