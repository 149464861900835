import React, {useState,useEffect} from 'react';
import Header3 from './header3'; // Import your Header component
import axios from "axios";
import Swal from 'sweetalert2';
import BASE_URL from './config';
import withReactContent from 'sweetalert2-react-content'
import Footer from './footer'; // Import your Footer component
import worldRaces from './races';
import BusinessCategories from './BusinessCategories';
import Select from 'react-select';


function Newstudent(){
  const MySwal = withReactContent(Swal);

  const [state , setState] = useState({
    username: '',
    userid: '',
    fname: '',
    lname: '',
    email: '',
    race: '',
    ee_number: '',
    gender: '',
    role: '',
    phone: '',
    business_unit: '',
    title_role: '',
    secondphone: '',
    secondemail: '',
})

const token = localStorage.getItem('sl_med_tokenize');
const headers2 = { 
  headers: {
    'AccessToken': JSON.parse(token),
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': "*",
  }
};

const handleChange2 = (fieldName) => (selectedOption) => {
  setState((prevState) => ({
    ...prevState,
    [fieldName]: selectedOption.value
  }));
};


const uploadsupplier = (e) => {
  window.location.href='uploadsupplier'
}

const addStudent = async (e) => {
  e.preventDefault();
  if (!state.fname ||!state.lname || !state.username|| !state.userid|| !state.title_role|| !state.gender|| !state.race|| !state.business_unit|| !state.title_role|| !state.ee_number|| !state.phone|| !state.email) {
    // console.log(state)
    MySwal.fire({
      title: <p>Please enter all required fields</p>,
      didOpen: () => {
      },
    }).then(() => {
    });
  }else{
    MySwal.showLoading()
     const dashURL = BASE_URL+'/createStudent'
     const postData = {
      accesstoken: localStorage.getItem("ladpsouthafricastudentaccesstoken"),
      username: state.username,
      userid: state.userid,
      fname: state.fname,
      lname: state.lname,
      email: state.email,
      race: state.race,
      ee_number: state.ee_number,
      gender: state.gender,
      role: state.title_role,
      phone: state.phone,
      business_unit: state.business_unit,
      title_role: state.title_role,
      secondphone: state.secondphone,
      secondemail: state.secondemail,
     }

     console.log(postData);
     MySwal.fire({
         title: <p>please wait...</p>,
         didOpen: () => {
           MySwal.showLoading()
           axios.post(dashURL, postData, headers2)
         .then((response) => {
           console.log("response received: ", response);
           Swal.close()
           Swal.fire({
             title: "Successful",
             text: "Student Created Successfully",
             icon: "success",
             showCancelButton: false,
             confirmButtonText: "Okay",
             closeOnCancel: true,
             confirmButtonColor: '#3085d6',
           }).then(result => {
         window.location.href = 'studentlist';
           })
           })
           .catch((err) => {
           Swal.close()
             console.log("AXIOS ERROR: ", err);
             if(!err.response){
             return MySwal.fire(err.message)
             }else{
             return MySwal.fire(err.response.data.message)
             }
           })
         },
       }).then(() => {
       })
 }
};



  const handleChange = (e) => {
    const {id , value} = e.target   
    setState(prevState => ({
        ...prevState,
        [id] : value
    }))
  }
      return (
     <div>
     <Header3/>
     <br/>
     <br/>
     <br/>
     <br/>
      <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start  container-xxl ">
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card mb-5 mb-xl-8">
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">Add New Student</span>
                  
                </h3>
                <div className="card-toolbar">
                 <button onClick={uploadsupplier}  className="btn btn-success btn-active-light-success me-2">Upload File</button>
                 </div>
              </div>
              <div className="card-body py-3">
              <form id="kt_account_profile_details_form" className="form">
            <div className="card-body border-top p-9">
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Student ID</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.userid} id="userid" name="userid" onChange={handleChange} />
                </div>
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Student Firstname</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.fname} id="fname" name="fname" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                <label className="required fw-semibold fs-6 mb-2">Student Lastname</label>
                <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.lname} id="lname" name="lname" onChange={handleChange} />
               </div>
              </div>
              
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
              <label className="required fw-semibold fs-6 mb-2">Student Username</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.username} id="username" name="username" onChange={handleChange} />
                </div>
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Student EE Number</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={state.ee_number} id="ee_number" name="ee_number" onChange={handleChange} />
                </div>
                  
                <div className='col-lg-4'>
                        <label className=" fw-semibold fs-6 mb-2">Student Race</label>
                        <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.race, label: state.race }}  onChange={handleChange2('race')}  
                           options={worldRaces}
                        />
                      </div>
               
              </div>
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Student Gender</label>
                  <select  aria-label="Select"  value={state.gender} id="gender" name="gender" onChange={handleChange}  className="form-select form-select-solid form-select-lg">
                    <option value>Select...</option>
                    <option  value="MALE">MALE</option>
                    <option  value="FEMALE">FEMALE</option>
                  </select>
                </div>

              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Student Email</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.email} id="email" name="email" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                <label className="required fw-semibold fs-6 mb-2">Student Phone</label>
                <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.phone} id="phone" name="phone" onChange={handleChange} />
               </div>
               </div>
              
               <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Business unit</label>
                  <input  value={state.business_unit} className="form-control" id="business_unit" name="business_unit" onChange={handleChange} />
                </div>
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Title at Company</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.title_role} id="title_role" name="title_role" onChange={handleChange} />
                </div>
               </div>
            </div>
            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <a href='studentlist'  className="btn btn-light btn-active-light-danger me-2">Discard</a>
              <button type="submit" onClick={addStudent} className="btn btn-info" id="kt_account_profile_details_submit">Create Student</button>
            </div>
          </form>
              </div>
            </div>
          </div>
          </div>
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Newstudent;
