import React, {useState,useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import Header3 from './header3'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import axios from "axios";
import DataTable from "react-data-table-component";
import Select from 'react-select';
import Spinner from 'react-bootstrap/Spinner';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import BASE_URL from './config';
import Swal from 'sweetalert2';
import dayjs from "dayjs";
import withReactContent from 'sweetalert2-react-content'


function Cohortstudents(){
  const MySwal = withReactContent(Swal)
  const [org_info, setorg] = useState(['']);
  const [coht_info, setcoht] = useState([]);
  const deleteURL = BASE_URL+'/deletecohortresultbyID'
  const cohortresultURL = BASE_URL+'/fetchallcohortresultbyID'
  const token = localStorage.getItem("ladpsouthafricaaccesstoken");
  const urlParams = new URLSearchParams(window.location.search);
  const myidd = urlParams.get('myid');
  const history = useHistory();
  const cohortURL = BASE_URL+'/fetchcohortbyID'
    
  const options = [
    { value: 'Orientation', label: 'Orientation' },
    { value: 'Emotional Competence', label: 'Emotional Competence' },
    { value: 'Stress & Wellbeing ', label: 'Stress & Wellbeing' },
    { value: 'Summative1', label: 'Summative 1' },
    { value: 'Summative2', label: 'Summative 2' },
    { value: 'Coaching Session', label: 'Coaching Session' },
    { value: 'Driving Service Excellence', label: 'Driving Service Excellence' },
    { value: 'Assertiveness & Managing Tough Interactions', label: 'Assertiveness & Managing Tough Interactions' },
    { value: 'Uncovering Customer Needs', label: 'Uncovering Customer Needs' },
  ];

  const [state , setState] = useState({
    courses: ''
})
      
  const handleChange2 = (fieldName) => (selectedOption) => {
    setState((prevState) => ({
      ...prevState,
      [fieldName]: selectedOption.value
    }));
  };

  const handleGoBack = () => {
    history.goBack();
  };


  const headers2 = { 
    headers: {
      'AccessToken': token,
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': "*",
    }
  };

  const body = {
    'accesstoken': localStorage.getItem("ladpsouthafricastudentaccesstoken"),
    'cohort_id': myidd

  }

  const getcohortinfo = async (e) => {
    axios.post(cohortresultURL, body, headers2)
    .then((response) => {
      console.log("response received: ", response);
      if(!response){
        setcoht(' ');
      }else{
        const sortedArray = response.data.data;
        // const numDescending = response.data.data;
        const numDescending = [...sortedArray].sort((a, b) => b.id - a.id);
        setcoht(numDescending);
        setcoht(numDescending);
      }
    });
  };

  useEffect(() => {
    getcohortinfo();
  }, []);


  const columns = [
    {
      name: 'DATE',
      selector: (row) => dayjs(row.created_at).format("DD MMM YY"),
      sortable: true,
      width: "10rem" ,
        cellExport: (row) => dayjs(row.created_at).format("DD MMM YY")
  },
    {
      name: 'USERNAME ',
      selector: (row) => row.Username,
      sortable: true,
      width: '12rem',
      cellExport: row => row.Username 
    },
    {
      name: 'NAME',
      selector: (row) => row.name,
      sortable: true,
      width: '10rem',
      cellExport: row => row.name 

    },
    {
      name: 'EE NUMBER',
      selector: (row) => row.ee_number,
      sortable: true,
      width: '12rem',
      cellExport: row => row.ee_number 
    },
    {
      name: 'RACE',
      selector: (row) => row.race,
      sortable: true,
      width: '12rem',
      cellExport: row => row.race 
    },
    {
      name: 'GENDER',
      selector: (row) => row.gender,
      sortable: true,
      width: '12rem',
      cellExport: row => row.gender 
    },
    
    {
      name: 'ACTIONS',
      button: true,
      width: '15rem',
      cell: (row) => <button onClick={() => viewstudents(row.Username)} className="btn btn-sm btn-info btn-active-light-primary">View</button>
    },
];

const data=coht_info;
const tableData = {
  columns,
  data
};


const viewstudents = (selectedId) => {
  const selectedID = selectedId;
  if (selectedID) {
    const nextPageUrl = '/viewstudent'; 
    window.location.href = `${nextPageUrl}?myid=${myidd}&username=${selectedID}`;
  } else {
    console.error(`Data for ID ${selectedId} not found`);
  }
}

      return (
     <div>
     <Header3/>
     <div>
        <div className="toolbar py-3 py-lg-5" >
          <div className=" container-xxl  d-flex flex-stack flex-wrap">
            <div className="page-title d-flex flex-column">
              <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
               Cohort Result 
                <span className="page-desc text-muted fs-7 fw-semibold pt-1">
                </span>
              </h1>
            </div>
          </div>
        </div>
        <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start  container-xxl ">
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card mb-5 mb-xl-8">
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">Students for {coht_info.cohorts_title} </span>
                </h3>
              </div>
              <div className="card-body py-3">
                {/*begin::Table container*/}
                <div className="table-responsive">
                  {/*begin::Table*/}
                  <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                  {coht_info == null ? <Spinner animation="border" /> : ""}
                  <DataTableExtensions {...tableData}>
                                <DataTable className=""
                                  columns={columns}
                                  data={data}
                                  noHeader
                                  defaultSortField="id"
                                  defaultSortAsc={false}
                                  pagination
                                  exportHeaders= {true}
                                  highlightOnHover
                                />
                              </DataTableExtensions>
                  </table>
                  {/*end::Table*/}
                </div>
                {/*end::Table container*/}
              </div>
              {/*begin::Body*/}
            </div>
          </div>
          </div>
      </div>
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };


export default Cohortstudents;
