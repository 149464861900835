import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import Header3 from './header3'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import withReactContent from 'sweetalert2-react-content'
import BASE_URL from './config';
import axios from "axios";

function Studenthome(){
      const MySwal = withReactContent(Swal)
      const [org_info, setorg] = useState([]);
      const [home_info, sethome] = useState([]);
      const orgURL = BASE_URL+'/summary'
      const homeURL = BASE_URL+'/fetchcohorts'
      const body = {
        'accesstoken': localStorage.getItem("ladpsouthafricastudentaccesstoken"),
      }

      const headers2 = { 
        headers: {
          // 'AccessToken': JSON.parse(token),
          'Content-Type': 'application/json;charset=UTF-8',
          'Access-Control-Allow-Origin': "*",
        }
      };

      const getorginfo = async (e) => {
        axios.post(orgURL, body, headers2)
        .then((response) => {
          console.log("response received: ", response);
          if(!response){
            setorg(0);
          }else{
            const sortedArray = response.data.data;
            console.log(sortedArray)
            setorg(sortedArray);
            setorg(sortedArray);
          }
        });
      };

      const gethomeinfo = async (e) => {
        axios.post(homeURL, body, headers2)
        .then((response) => {
          console.log("response received: ", response);
          if(!response){
            sethome(0);
          }else{
            const sortedArray = response.data.data;
            sortedArray.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            const mostRecentThree = sortedArray.slice(0, 4);
            console.log(mostRecentThree)
            sethome(mostRecentThree);
            sethome(mostRecentThree);
          }
        });
      };


      useEffect(() => {
        getorginfo();
        gethomeinfo();
      }, []);


      return (
              <div>
     <Header3/>
        
     <div className="toolbar py-3 py-lg-5" id="kt_toolbar">
          <div id="kt_toolbar_container" className=" container-xxl  d-flex flex-stack flex-wrap">
            <div className="page-title d-flex flex-column">
              <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                Dashboard
                <span className="page-desc text-muted fs-7 fw-semibold pt-1">
                </span>
              </h1>
            </div>
          </div>
        </div>
        <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start  container-xxl ">
          <div className="content flex-row-fluid" id="kt_content">
            <div className="row g-5 g-lg-10">
              {/*begin::Col*/}
              <div className="col-xl-4 mb-xl-10">
                {/*begin::Mixed Widget 19*/}
                <div className="card h-md-100">
                  {/*begin::Beader*/}
                  <div className="card-header border-0 py-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">Recently Created Cohorts</span>
                      {/* <span className="text-muted fw-semibold fs-7">You have created 0 cohorts in total</span> */}
                    </h3>
                  </div>
                  <div className="card-body p-0 d-flex flex-column">
                    <div className="card-px pt-5 pb-10 flex-grow-1">
                    <div>
                    {home_info.map(item => (
                      <div key={item.id} className="d-flex align-items-sm-center mb-7">
                        <div className="d-flex align-items-center flex-row-fluid flex-wrap">                    
                          <div className="flex-grow-1 me-2">
                            <a href="#" className="text-gray-800 text-hover-primary fs-6 fw-bold">{item.cohorts_title}</a>
                            <span className="text-muted fw-semibold d-block fs-7">{item.cohort_description_id}</span>
                          </div>                     
                          <span className="badge badge-light fw-bold my-2">{new Date(item.created_at).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                    </div>
                    <div id="kt_charts_mixed_widget_19_chart" className="card-rounded-bottom" style={{height: '150px'}} />
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="row g-5 g-lg-10">
                  <div className="col-lg-6 mb-lg-10">  
                    <a href="#" className="card  h-150px" style={{backgroundColor: '#932788'}}>
                      <div className="card-body d-flex flex-column justify-content-between">
                        <div className="d-flex flex-column">
                          <div className="text-white fw-bold fs-1 mb-0 mt-5">
                          {org_info.totalCohorts}
                          </div>
                          <div className="text-white fw-semibold fs-6">
                            Cohorts
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-6 mb-lg-10">  
                    <a href="#" className="card  h-150px" style={{backgroundColor: '#932788'}}>
                      <div className="card-body d-flex flex-column justify-content-between">
                        <div className="d-flex flex-column">
                          <div className="text-white fw-bold fs-1 mb-0 mt-5">
                          {org_info.totalTutors}
                          </div>
                          <div className="text-white fw-semibold fs-6">
                            Tutors
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                {/*begin::Row*/}
                <div className="row g-5 g-lg-10">
                  <div className="col-lg-12 mb-lg-10">  
                    <a href="#" className="card h-150px" style={{backgroundColor: '#932788'}}>
                      <div className="card-body d-flex flex-column justify-content-between">
                        <div className="d-flex flex-column">
                          <div className="text-white fw-bold fs-1 mb-0 mt-5">
                            {org_info.totalStudents}
                          </div>
                          <div className="text-white fw-semibold fs-6">
                            Students Created
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                 
                </div>
                <a href="#" className="card bgi-no-repeat h-150px mb-xl-10" style={{backgroundColor: '#932788', backgroundPosition: 'calc(100% + 1rem) bottom', backgroundSize: '25% auto', backgroundImage: 'url("assets/media/svg/humans/custom-1.svg")'}}>
                  <div className="card-body d-flex flex-column align-items-start justify-content-center">
                    <p className="text-white fs-7">
                      Upload results directly for each cohort and generate reports instantly. <br/>Click the button above to start.
                    </p>
                  </div>
                </a>
              </div>
              <div className="col-xl-4 mb-xl-10">
                <div className="card bgi-no-repeat pt-500 h-xl-100 mb-5 mb-lg-10" style={{backgroundColor: '#932788', backgroundPosition: '0 calc(110% + 0.5rem)', backgroundSize: '100% auto', backgroundImage: 'url("assets/media/Sunday.png")'}}>
                  <div className="card-body">
                    <h3 className="text-white fw-bold my-7">Generate Study Report</h3>
                    <p className="text-muted fs-6 mb-7">
                      Click on the button below to create  <br/>  a report for your study. 
                    </p>
                    <a href="/courseslist"  className="btn btn-primary gw-bold px-6 py-3">Begin</a>
                   
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-5 g-lg-10">
             
            </div>
            <br/>
           
          </div></div>




     <Footer/>
              </div>
               
        );
 
  };

export default Studenthome;
