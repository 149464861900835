const worldRaces = [
    { value: null, label: '--Select--' },
    { value: 'Asian', label: 'Asian' },
    { value: 'Black or African American', label: 'Black or African American' },
    { value: 'Native American', label: 'Native American' },
    { value: 'Coloured', label: 'Coloured' },
    { value: 'Indian', label: 'Indian' },
    { value: 'White', label: 'White' },
    { value: 'Hispanic or Latino', label: 'Hispanic or Latino' },
    { value: 'Arab', label: 'Arab' },
    { value: 'Multiracial', label: 'Multiracial' },
    { value: 'other', label: 'Other' }
];

export default worldRaces;