import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import Header3 from './header3.js'; // Import your Header component
import Footer from './footer.js'; // Import your Footer component
import Countries from './countries.js';
import withReactContent from 'sweetalert2-react-content'
import BusinessCategories from './BusinessCategories.js';
import CitiesAndProvincesSouthAfrica from './cities.js';
import BBEEE from './bbeee.js';
import Select from 'react-select';
import BASE_URL from './config.js';
import axios from "axios";


function Newtraining(){
  const MySwal = withReactContent(Swal)
  const orgURL = BASE_URL+'/fetchTutors'
  
  const [org_info, setorg] = useState([]);
  const [state , setState] = useState({
    cohorts_title: '',
    cohort_description_id: '',
    tutor_id: '',
    tutor_name: '',
    courses:'',
})


const body = {
  'accesstoken': localStorage.getItem("ladpsouthafricastudentaccesstoken"),

}

const getorginfo = async (e) => {
  axios.post(orgURL, body, headers2)
  .then((response) => {
    console.log("response received: ", response);
    if(!response){
      setorg(0);
    }else{
      const sortedArray = response.data.data;
      const numDescending = [...sortedArray].sort((a, b) => b.id - a.id);
      setorg(numDescending);
      setorg(numDescending);
    }
  });
};




useEffect(() => {
  getorginfo();
}, []);


const options = [
  { value: 'Orientation', label: 'Orientation' },
  { value: 'Emotional Competence', label: 'Emotional Competence' },
  { value: 'Stress & Wellbeing ', label: 'Stress & Wellbeing' },
  { value: 'Summative1', label: 'Summative 1' },
  { value: 'Summative2', label: 'Summative 2' },
  { value: 'Coaching Session', label: 'Coaching Session' },
  { value: 'Driving Service Excellence', label: 'Driving Service Excellence' },
  { value: 'Assertiveness & Managing Tough Interactions', label: 'Assertiveness & Managing Tough Interactions' },
  { value: 'Uncovering Customer Needs', label: 'Uncovering Customer Needs' },
];

  const [selectedTutors, setSelectedTutors] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]); 
  const [selectedCourses, setcoursevalue] = useState([]);

  const handleTutorChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };

const handleChange = (e) => {
  const {id , value} = e.target   
  setState(prevState => ({
      ...prevState,
      [id] : value
  }))
} 

const handleChange2 = (event) => {
  setState({ ...state, tutor_id: event.target.value });
};

const token = localStorage.getItem('sl_med_tokenize');
const headers2 = { 
  headers: {
    'AccessToken': JSON.parse(token),
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': "*",
  }
};

const createOrg = async (e) => {
  e.preventDefault();
  const jsonSelectedCourses = JSON.stringify(selectedOptions);
  setcoursevalue(jsonSelectedCourses);
  // console.log(selectedValues)
  if (!state.cohorts_title || !state.cohort_description_id|| !state.tutor_id) {
    MySwal.fire({
      title: <p>Please enter all required fields</p>,
      confirmButtonColor: '#3085d6',
      didOpen: () => {
      },
    }).then(() => {
    });
  }else{
     MySwal.showLoading()
     const dashURL = BASE_URL+'/createCohort'
     const selectedTutor = JSON.parse(state.tutor_id);
     const postData = {
        'cohorts_title': state.cohorts_title,
        'cohort_description_id': state.cohort_description_id,
        'tutor_id': selectedTutor.id,
        'tutor_name': selectedTutor.firstname+' '+selectedTutor.lastname,
        'courses':jsonSelectedCourses,
        'accesstoken': localStorage.getItem("ladpsouthafricastudentaccesstoken"),
     }
     console.log(postData);
      Swal.close()
     MySwal.fire({
         title: <p>please wait...</p>,
         didOpen: () => {
           MySwal.showLoading()
           axios.post(dashURL, postData, headers2)
         .then((response) => {
           console.log("response received: ", response);
           Swal.close()
           Swal.fire({
             title: "Successful",
             text: "Cohort Created successfully",
             icon: "success",
             showCancelButton: false,
             confirmButtonColor: '#3085d6',
             confirmButtonText: "Okay",
             closeOnCancel: true,
           }).then(result => {
         window.location.href = 'courseslist';
           })
           })
           .catch((err) => {
           Swal.close()
             console.log("AXIOS ERROR: ", err);
             if(!err.response){
             return MySwal.fire(err.message)
             }else{
             return MySwal.fire(err.response.data.message)
             }
           })
         },
       }).then(() => {
       })
 }
};


      return (
     <div>
     <Header3/>
     <br/>
     <br/>
     <br/>
     <br/>
     <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start  container-xxl ">
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card mb-5 mb-xl-8">
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">Add New Cohort</span>
                </h3>
              </div>
              <div className="card-body py-3">
              <form id="kt_account_profile_details_form" className="form">
            <div className="card-body border-top p-9">
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Cohort Name</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.cohorts_title} id="cohorts_title" name="cohorts_title" onChange={handleChange} />
                </div>
                
               <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Cohort Description</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={state.cohort_description_id} id="cohort_description_id" name="cohort_description_id" onChange={handleChange} />
                </div>

                <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Assign Coach</label>
                  <select aria-label="Select" value={state.tutor_id} id="tutor_id" name="tutor_id" onChange={handleChange2} className="form-select form-select-solid form-select-lg">
                    <option value="">Select...</option>
                    {org_info.map((org, index) => (
                      <option key={index}  value={JSON.stringify({ id: org.id, firstname: org.firstname, lastname: org.lastname })}>{org.firstname} {org.lastname}</option>
                    ))}
                  </select>
                </div>
               </div>
              
               <div className="fv-row mb-12 col-12 row">
               <div className="col-lg-4">
                <label className="required fw-semibold fs-6 mb-2">Assign Courses</label>
                <Select 
                  isMulti
                  options={options}
                  value={selectedOptions}
                  onChange={handleTutorChange}
                  className="form-select form-select-solid form-select-lg"
                />
              </div>
                </div>
             
            </div>
            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <a href='courseslist'  className="btn btn-light btn-active-light-danger me-2">Discard</a>
              <button onClick={createOrg} className="btn btn-info" id="kt_account_profile_details_submit">Create Cohort</button>
            </div>
          </form>
              </div>
              {/*begin::Body*/}
            </div>
          </div>
          </div>
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Newtraining;
