import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import { checkAuthToken } from './component/authUtils';
import Home from './component/home';
import Orghome from './component/orghome';
import Studenthome from './component/studenthome';
import Tutors from './component/tutors';
import Viewtutor from './component/viewtutor';
import Newtutor from './component/newtutor';
import Studentlist from './component/studentlist';
import Printquote from './component/printquote';
import Studentsettings from './component/studentsettings';
import Courseslist from './component/courseslist';
import Vieworganization from './component/vieworganization';
import Reports from './component/reports';
// import Allbookings from './component/allbookings';
import Orgsettings from './component/orgsettings';
import Newsupplier from './component/newsupplier';
import Newstudent from './component/newstudent';
import Uploadresults from './component/uploadresults';
import Newtraining from './component/newtraining';
import Editorganization from './component/editorganization';
import Cohortstudents from './component/cohortstudents';
import Viewapprovals from './component/viewapprovals';
import Orgsupplier from './component/orgsuppliers';
import Orgquotes from './component/orgquotes';
import Orgnewquote from './component/orgnewquote';
import Orgviewquote from './component/orgviewquote';
import Allquotes from './component/allquotes';
import Orgfilter from './component/orgfilter';
import Viewcohorts from './component/viewcohorts';
import Viewstudent from './component/viewstudent';
import Orgviewsupplier from './component/orgviewsupplier';
import Signin from './component/signin';
import Orgsignin from './component/login';
import Launch from './component/launch';
import Allpayments from './component/allpayments';
import Settings from './component/settings';
import Editstudent from './component/editstudent';
import Forgotpassword from './component/forgotpassword';
import Filter from './component/filter';
import Users from './component/users';
import Editquote from './component/editquote';
import Viewuser from './component/viewuser';
import Adduser from './component/adduser';
import Organizations from "./component/organizations";
import Viewquote from "./component/viewquote";
import Updatesupplier from "./component/updatesupplier";
import pendingapprovals from "./component/pendingapprovals";


const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuthToken() ? (
        <Component {...props} />
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

export default function BasicExample() {

  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/" component={Signin}/>
          <Route exact path="/adminsignin" component={Signin}/>
          <PrivateRoute exact path="/home" component={Home}/>
          <PrivateRoute exact path="/orghome" component={Orghome}/>
          <PrivateRoute exact path="/tutors" component={Tutors}/>
          <PrivateRoute exact path="/viewtutor" component={Viewtutor}/>
          <PrivateRoute exact path="/newtutor" component={Newtutor}/>
          <PrivateRoute exact path="/studenthome" component={Studenthome}/>
          <PrivateRoute exact path="/studentlist" component={Studentlist}/>
          <Route exact path="/printquote" component={Printquote}/>
          <PrivateRoute exact path="/studentsettings" component={Studentsettings}/>
          <PrivateRoute exact path="/courseslist" component={Courseslist}/>
          <Route exact path="/managequote" component={Editquote}/>
          <Route exact path="/updatesupplier" component={Updatesupplier}/>
          <Route path="/signin" component={Orgsignin}/>
          <Route exact path="/orgviewsupplier" component={Orgviewsupplier}/>
          <Route exact path="/orgviewquote" component={Orgviewquote}/>
          <Route exact path="/orgsettings" component={Orgsettings}/>
          <Route exact path="/orgnewquote" component={Orgnewquote}/>
          <PrivateRoute exact path="/user" component={Users}/>
          <PrivateRoute exact path="/pendingapprovals" component={pendingapprovals}/>
          <PrivateRoute exact path="/viewapprovals" component={Viewapprovals}/>
          <PrivateRoute exact path="/viewuser" component={Viewuser}/>
          <PrivateRoute exact path="/allquotes" component={Allquotes}/>
          <PrivateRoute exact path="/viewquote" component={Viewquote}/>
          <PrivateRoute exact path="/uploadresults" component={Uploadresults}/>
          <PrivateRoute exact path="/adduser" component={Adduser}/>
          <PrivateRoute exact path="/forgotpassword" component={Forgotpassword}/>
          <PrivateRoute exact path="/reports" component={Reports}/>
          <PrivateRoute exact path="/vieworganization" component={Vieworganization}/>
          <PrivateRoute exact path="/editorganization" component={Editorganization}/>
          <PrivateRoute exact path="/editstudent" component={Editstudent}/>
          <PrivateRoute exact path="/organizations" component={Organizations}/>
          {/* <PrivateRoute exact path="/allbookings" component={Allbookings}/> */}
          <PrivateRoute exact path="/newsupplier" component={Newsupplier}/>
          <PrivateRoute exact path="/newstudent" component={Newstudent}/>
          <PrivateRoute exact path="/newtraining" component={Newtraining}/>
          <PrivateRoute exact path="/cohortstudents" component={Cohortstudents}/>
          <Route exact path="/orgsuppliers" component={Orgsupplier}/>
          <Route exact path="/orgquotes" component={Orgquotes}/>
          <Route exact path="/orgfilter" component={Orgfilter}/>
          <PrivateRoute exact path="/viewcohorts" component={Viewcohorts}/>
          <PrivateRoute exact path="/viewstudent" component={Viewstudent}/>
          <PrivateRoute exact path="/filter" component={Filter}/>
          <PrivateRoute exact path="/allpayments" component={Allpayments}/>
          <PrivateRoute exact path="/settings" component={Settings}/>
          {/* <Route path="/changepassword" component={Changepassword}/> */}
          {/* <Route path="/payments" component={Payments}/> */}

         
            
          
        </Switch>
      </div>
    </Router>
  );
}



