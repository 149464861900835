import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import Header3 from './header3.js'; // Import your Header component
import Footer from './footer.js'; // Import your Footer component
import Countries from './countries.js';
import { useHistory } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content'
import CitiesAndProvincesSouthAfrica from './cities.js';
import BusinessCategories from './BusinessCategories.js';
import BBEEE from './bbeee.js';
import worldRaces from './races';
import Select from 'react-select';
import BASE_URL from './config.js';
import axios from "axios";
import storage from "./firebaseConfig.js"
import {
  ref,
  uploadBytesResumable,
  getDownloadURL 
} from "firebase/storage";


function Viewtutor(){
  const MySwal = withReactContent(Swal)
  const [supp_info, setsupp] = useState([]);
  const [org_info, setorg] = useState([]);
  const suppURL = BASE_URL+'/fetchTutorById'
  const orgupdateURL = BASE_URL+'/editTutor'
  const orgURL = BASE_URL+'/fetchclients'
  const urlParams = new URLSearchParams(window.location.search);
  const myidd = urlParams.get('myid');
  const history = useHistory();
  const [state , setState] = useState({
    firstname: '',
    lastname: '',
    contactphone: '',
    contactemail: '',
})

  const body = {
    'accesstoken': localStorage.getItem("ladpsouthafricastudentaccesstoken"),
    'tutorid': myidd
  }

  const handleGoBack = () => {
    history.goBack();
  };


  const getsuppinfo = async (e) => {
    axios.post(suppURL, body, headers2)
    .then((response) => {
      console.log("response received: ", response);
      if(!response){
        setsupp(0);
      }else{
        const sortedArray = response.data.data;
        console.log(sortedArray)
        setsupp(sortedArray);
        setsupp(sortedArray);
      }
    });
  };

  

  const handleChange2 = (fieldName) => (selectedOption) => {
    setState((prevState) => ({
      ...prevState,
      [fieldName]: selectedOption.value
    }));
  };
  

  useEffect(() => {
    getsuppinfo();
      // When org_info.firstname changes, update state.patientName
      setState((prevState) => ({
        ...prevState,
        firstname: supp_info.firstname,
        lastname: supp_info.lastname,
        contactphone: supp_info.contactphone,
        contactemail: supp_info.contactemail,
      }));
    }, [supp_info.contactemail]);


const handleChange = (e) => {
  const {id , value} = e.target   
  setState(prevState => ({
      ...prevState,
      [id] : value
  }))
} 

const token = localStorage.getItem('sl_med_tokenize');


const headers2 = { 
  headers: {
    'AccessToken': JSON.parse(token),
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': "*",
  }
};

const updatetutor = async (e) => {
  e.preventDefault();
  if (!state.firstname ||!state.lastname || !state.contactphone|| !state.contactemail) {
    MySwal.fire({
      title: <p>Please enter all required fields</p>,
      confirmButtonColor: '#3085d6',
      didOpen: () => {
      },
    }).then(() => {
    });
  }else{
     MySwal.showLoading()
     const postData = {
        'accesstoken': localStorage.getItem("ladpsouthafricastudentaccesstoken"),
        firstname: state.firstname,
        lastname: state.lastname,
        contactphone: state.contactphone,
        contactemail: state.contactemail,
        id: myidd
       
     }
     console.log(postData);
      Swal.close()
     MySwal.fire({
         title: <p>please wait...</p>,
         didOpen: () => {
           MySwal.showLoading()
           axios.post(orgupdateURL, postData, headers2)
         .then((response) => {
           console.log("response received: ", response);
           Swal.close()
           Swal.fire({
             title: "Successful",
             text: "Coach Updated successfully",
             icon: "success",
             showCancelButton: false,
             confirmButtonColor: '#3085d6',
             confirmButtonText: "Okay",
             closeOnCancel: true,
           }).then(result => {
         window.location.reload(false);
           })
           })
           .catch((err) => {
           Swal.close()
             console.log("AXIOS ERROR: ", err);
             if(!err.response){
             return MySwal.fire(err.message)
             }else{
             return MySwal.fire(err.response.data.message)
             }
           })
         },
       }).then(() => {
       })
 }
};




      return (
     <div>
     <Header3/>
     <br/>
     <br/>
     <br/>
     <br/>
    
     <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start  container-xxl ">
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card mb-5 mb-xl-8">
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">Edit Coach Information</span>
                  
                </h3>
               
              </div>
              <div className="card-body py-3">
              <form id="kt_account_profile_details_form" className="form">
                  <div className="card-body border-top p-9">
                    <div className="fv-row mb-12 col-12 row">
                    <div className='col-lg-4'>
                        <label className="required fw-semibold fs-6 mb-2">Firstname</label>
                        <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.firstname} id="firstname" name="firstname" onChange={handleChange} />
                      </div>  
                      <div className='col-lg-4'>
                        <label className="required fw-semibold fs-6 mb-2">Lastname</label>
                        <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.lastname} id="lastname" name="lastname" onChange={handleChange} />
                      </div>              
                      <div className='col-lg-4'>
                        <label className=" fw-semibold fs-6 mb-2">Phone </label>
                        <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.contactphone} id="contactphone" name="contactphone" onChange={handleChange} />
                      </div>
                    
                    </div>
                  
                    
                    <div className="fv-row mb-12 col-12 row">
                    <div className='col-lg-4'>
                        <label className="required fw-semibold fs-6 mb-2">Email</label>
                        <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.contactemail} id="contactemail" name="contactemail" onChange={handleChange} />
                      </div>
                    
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-end py-6 px-9">
                    <a href='tutors'  className="btn btn-light btn-active-light-danger me-2">Discard</a>
                    <button type="submit" className="btn btn-info" onClick={updatetutor}>Update Coach</button>
                  </div>
              </form>
              </div>
            </div>
          </div>
          </div>
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Viewtutor;
