import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import axios from "axios";
import withReactContent from 'sweetalert2-react-content'
import Header3 from './header3'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import dayjs from 'dayjs';
import Select from 'react-select';
import BASE_URL from './config';
import * as XLSX from 'xlsx';


function Uploadresults(){
  const MySwal = withReactContent(Swal);
  const uploadURL = BASE_URL+'/insertCohortresult';
  const orgURL = BASE_URL+'/fetchclients'
  const [org_info, setorg] = useState(['']);
  const [state , setState] = useState({
    file: '',
    course: ''
})
const urlParams = new URLSearchParams(window.location.search);
const myidd = urlParams.get('myid');


const options = [
  { value: 'Orientation', label: 'Orientation' },
  { value: 'Emotional Competence', label: 'Emotional Competence' },
  { value: 'Stress & Wellbeing ', label: 'Stress & Wellbeing' },
  { value: 'Summative1', label: 'Summative 1' },
  { value: 'Summative2', label: 'Summative 2' },
  { value: 'Coaching Session', label: 'Coaching Session' },
  { value: 'Driving Service Excellence', label: 'Driving Service Excellence' },
  { value: 'Assertiveness & Managing Tough Interactions', label: 'Assertiveness & Managing Tough Interactions' },
  { value: 'Uncovering Customer Needs', label: 'Uncovering Customer Needs' },
];

const downloadSampleFile = () => {
    const sampleFileUrl = 'assets/supplier_upload_sample_.xlsx';
    const fileName = 'cohort_upload_sample.xlsx';
  
    // Create a hidden anchor element
    const link = document.createElement('a');
    link.href = sampleFileUrl;
    link.download = fileName;
  
    // Simulate a click on the anchor element
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  

  const handleChange = (e) => {
    const {id , value} = e.target   
    setState(prevState => ({
        ...prevState,
        [id] : value
    }))
  } 

const handleChange2 = (fieldName) => (selectedOption) => {
  setState((prevState) => ({
    ...prevState,
    [fieldName]: selectedOption.value
  }));
};

const token = localStorage.getItem("ladpsouthafricastudentaccesstoken");
const headers2 = { 
  headers: {
    'AccessToken': token,
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': "*",
  }
};

const body = {
  'accesstoken': localStorage.getItem("ladpsouthafricastudentaccesstoken"),
}


  const clientoptions = org_info.map(org => ({
    value: org.id,
    label: org.clientname
  }));

  const handleSelectChange = (e) => {
    setState({
      ...state,
      file: e.target.files[0]
    });
  };

const blob = new Blob(['test'], { type: 'text/plain' });

const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };


  const excelDateToJSDate = (serial) => {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    const date_info = new Date(utc_value * 1000);
  
    const fractional_day = serial - Math.floor(serial) + 0.0000001;
  
    let total_seconds = Math.floor(86400 * fractional_day); // Change from const to let
  
    const seconds = total_seconds % 60;
  
    total_seconds -= seconds;
  
    const hours = Math.floor(total_seconds / (60 * 60));
    const minutes = Math.floor(total_seconds / 60) % 60;
  
    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please select a file.');
      return;
    }


  
    const fileReader = new FileReader();
    fileReader.onload = async (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        let jsonData = XLSX.utils.sheet_to_json(sheet);
        jsonData = jsonData.map(row => ({
          ...row,
          course_completed_on: row.course_completed_on ? dayjs(excelDateToJSDate(row.course_completed_on)).format('MM/DD/YYYY') : '',
          last_login_date: row.last_login_date ? dayjs(excelDateToJSDate(row.last_login_date)).format('MM/DD/YYYY') : '',
          course_started_on: row.course_started_on ? dayjs(excelDateToJSDate(row.course_started_on)).format('MM/DD/YYYY') : '',
        }));
        console.log(JSON.stringify(jsonData));
        console.log(jsonData);
        const formbody = {
          'accesstoken': localStorage.getItem("ladpsouthafricastudentaccesstoken"),
          'file': jsonData,
          'cohort_id': myidd,
          'course_name': state.course
        };
        console.log(formbody);
        MySwal.fire({
          title: <p>please wait...</p>,
          didOpen: () => {
            MySwal.showLoading();
            axios.post(uploadURL, formbody, headers2)
              .then((response) => {
                console.log("response received: ", response);
                Swal.close();
                Swal.fire({
                  title: "Successful",
                  text: "File Uploaded Successfully",
                  icon: "success",
                  showCancelButton: false,
                  confirmButtonText: "Okay",
                  confirmButtonColor: '#3085d6',
                  closeOnCancel: true,
                }).then(result => {
                  window.location.href= 'viewcohorts';
                });
              })
              .catch((err) => {
                Swal.close();
                console.error("AXIOS ERROR: ", err);
                if (!err.response) {
                  MySwal.fire(err.message);
                } else {
                  MySwal.fire(err.response.data.message);
                }
              });
          },
        });
      } catch (error) {
        console.error('Upload failed:', error);
      }
    };
  
    fileReader.readAsArrayBuffer(file);
  };
  


      return (
     <div>
     <Header3/>
     <br/>
     <br/>
     <br/>
     <br/>
    
     <div className="d-flex flex-column flex-xl-row">
        <div className="flex-lg-row-fluid ms-lg-15">
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="kt_customer_view_overview_tab" role="tabpanel">
            <div className="card mb-5 mb-xl-10">
        <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
          <div className="card-title m-0">
            <h3 className="fw-bold m-0">Upload Cohort Results</h3>
          </div>
          <div>
            <br/>
         
          <a  onClick={downloadSampleFile} className='btn btn-sm btn-success'>Download Sample Format for upload</a>
          </div>

        </div>
        <div id="kt_account_settings_profile_details" className="collapse show">
          <form id="kt_account_profile_details_form" className="form">
            <div className="card-body border-top p-9">
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-5'>
                <label className="required fw-semibold fs-6 mb-2">Select Course</label>
                <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.course, label: state.course }}  onChange={handleChange2('course')}  
                    options={options}
                />
                </div>
                <div className='col-lg-5'>
                <label className="required fw-semibold fs-6 mb-2">Upload File</label>
                <input 
                    type="file" className="form-control mb-3 mb-lg-0" id="file" name="file"  onChange={handleFileChange}
                />
                </div>
               </div>
            </div>
           
          </form>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
              {/* <a href='suppliers'  className="btn btn-light btn-active-light-danger me-2">Discard</a> */}
              <button onClick={handleUpload} className="btn btn-info" id="kt_account_profile_details_submit">Upload</button>
            </div>
        </div>
      </div>
            </div>
          </div>
        </div>
      </div>
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Uploadresults;
